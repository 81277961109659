import services from "@/shared/http";
import store from "@/store";

export const validateEmail = (value) =>
  services.validations.email(null, {
    partnerSlug: store.getters["partner/getPartnerSlug"],
    email: value,
  });

export const validateCelular = (value) =>
  services.validations.cel(null, {
    partnerSlug: store.getters["partner/getPartnerSlug"],
    phone: value,
  });
